import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/app/src/templates/Docs/index.tsx";
import Alert from '@material-ui/core/Alert';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "iframe-guide",
      "style": {
        "position": "relative"
      }
    }}>{`iFrame Guide`}<a parentName="h1" {...{
        "href": "#iframe-guide",
        "aria-label": "iframe guide permalink",
        "className": "anchor after"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a></h1>

    <Alert severity="warning" mdxType="Alert">
      <strong>NOTE:</strong> This legacy iframe endpoint has been deprecated and
will soon be disabled, please transition to using the{' '}
      <a href="/docs/v1/iframe-guide/">new iframe</a> as soon as possible
    </Alert>
    <p>{`TaleFin's systems can be integrated quickly, using an iframe, to process applications. The following HTML element is used to embed
our system into your existing website or architecture: `}</p>
    <deckgo-highlight-code {...{
      "language": "html"
    }}>{`
          `}<code parentName="deckgo-highlight-code" {...{
        "slot": "code"
      }}>{`<iframe src="https://banks.talefin.com/iframe/{VENDOR_LABEL}/{VENDOR_SPECIFIC_ID}/" width="auto" height="auto"/>`}</code>{`
        `}</deckgo-highlight-code>
    <p>{`This is the simplist possible integration and consists of a single line. The URL can also be sent in an email, sms or any other medium.`}</p>
    <p>{`The Vendor Specific ID component of the URL must be an identifier unique to the customer. This value will be returned to you in all
Webhook Events to help your internal systems identify the application. Whilst we recommend a UUID as a means of differentiating
customers and ensuring that there are no overlaps in the IDs for each, the restrictions for this UUID are quite lenient. The UUID
must simply be alphanumeric characters, including dashes and underscores that fit within a URL (2048 chars max).`}</p>
    <p>{`The Vendor Label component of the URL will be provided to you when your account is provisioned by TaleFin. `}</p>
    <h2 {...{
      "id": "iframe-dynamic-traversal",
      "style": {
        "position": "relative"
      }
    }}>{`iFrame Dynamic Traversal`}<a parentName="h2" {...{
        "href": "#iframe-dynamic-traversal",
        "aria-label": "iframe dynamic traversal permalink",
        "className": "anchor after"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a></h2>
    <p>{`The iframe is designed as such that you can start at any page before credentials to access the bank are requested.`}</p>
    <p>{`The following url string parameters can be used to start you at any particular part of the site: `}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": "left"
          }}>{`URL`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Outcome`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`/iframe/{vendor-label}/{vendor-id}/`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`This will start you at the base page with nothing else enabled.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`/iframe/{vendor-label}/{vendor-id}/tnc/`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`This will start you at the base page with the terms and conditions enabled.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`/iframe/{vendor-label}/{vendor-id}/do_mygov/`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`This will start you at the base page and send the user to fill in their login credentials for mygov before sending you to the bank page to select your bank.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`/iframe/{vendor-label{/{vendor-id}/tnc/do_mygov/`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`This will start you at the base page with the terms and conditions enabled and send the user to fill in their login credentials for mygov before sending you to the bank page to select your bank.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`/iframe/{vendor-label}/{vendor-id}/mygov/{full-name}/{mobile}/{email}/`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`This will start you at the mygov page before sending you to the bank page to select your bank.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`/iframe/{vendor-label}/{vendor-id}/bank/{full-name}/{mobile}/{email}/`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`This will start you at the bank page for the user to select their bank.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`/iframe/{vendor-label}/{vendor-id}/login/{full-name}/{mobile}/{email}/{bank-id}`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`This will start you directly at the login page for the bank that you have selected.`}</td>
        </tr>
      </tbody>
    </table>
    <p>{`Similarly, should you wish to simply run the crawl without filling in any personal details, even within the url, then an option has been provided for this as well.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": "left"
          }}>{`URL`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Outcome`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`/iframe/{vendor-label}/{vendor-id}/mygov/`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`This will start you at the mygov page before sending you to the bank page to select your bank. No personal information is needed from the user`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`/iframe/{vendor-label}/{vendor-id}/bank/`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`This will start you at the bank page for the user to select their bank. No personal information is needed from the user`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`/iframe/{vendor-label}/{vendor-id}/login/`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`This will start you directly at the login page for the bank that you have selected. No personal information is needed from the user`}</td>
        </tr>
      </tbody>
    </table>
    <p>{`Traversing directly along the URL to dynamically load in only the elements of the iframe that you
need in your application makes this system highly adaptable to your needs. However, validation on
entries within the URL is run before anything loads. As such, you must make sure that your URLs have
been properly set up if attempting this. The following table provides a breakdown of how everything
is processed within the URL string.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": "left"
          }}>{`String Name`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`vendor-label`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`This has been provided by the TaleFin team before starting your applications with us. The Vendor Label will be between 3 to 5 characters.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`vendor-id`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`This is the UUID that you have generated to track your applications through the system. This id must be a minimum of 7 alphanumeric characters (including dashes and underscores) and a max of 64.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`bank-id`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`The bank-id is the number used to identify which bank the user has selected as theirs. A list of the banks and their ids can be found here`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`mobile`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Mobile must be composed of numbers only.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`full-name`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`No restrictions have been placed on this parameter.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`email`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`This parameter handles both urlencoded and non-urlencoded inputs.`}</td>
        </tr>
      </tbody>
    </table>
    <h2 {...{
      "id": "dynamic-iframe-resizing",
      "style": {
        "position": "relative"
      }
    }}>{`Dynamic iFrame Resizing`}<a parentName="h2" {...{
        "href": "#dynamic-iframe-resizing",
        "aria-label": "dynamic iframe resizing permalink",
        "className": "anchor after"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a></h2>
    <p>{`Due to cross-site origin request issues it can be quite difficult to dynamically resize iframes. The best method would be to generate CSS media requests that output the required size for the iframe embed depending upon the page. These iframe updates can be generated through the onload request through the iframe, e.g.`}</p>
    <deckgo-highlight-code {...{
      "language": "html"
    }}>{`
          `}<code parentName="deckgo-highlight-code" {...{
        "slot": "code"
      }}>{`<iframe id="IframeId" src="https://banks.talefin.com/i/test/1/" onload="JSFunctionCallingCSSUpdates()"/>`}</code>{`
        `}</deckgo-highlight-code>
    <p>{`However, such a system is heavily reliant upon you to build. In order to get dynamic iframe resizing easily and quickly implemented TaleFin has implemented postMessages that notify the host of the current screen size requirements.`}</p>
    <h3 {...{
      "id": "postmessage",
      "style": {
        "position": "relative"
      }
    }}>{`postMessage`}<a parentName="h3" {...{
        "href": "#postmessage",
        "aria-label": "postmessage permalink",
        "className": "anchor after"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a></h3>
    <deckgo-highlight-code {...{
      "language": "js"
    }}>{`
          `}<code parentName="deckgo-highlight-code" {...{
        "slot": "code"
      }}>{`postMessage({"FrameHeight": height}, "*");`}</code>{`
        `}</deckgo-highlight-code>
    <p>{`Using these postMessages you can easily listen for the event changes and programmatically update when required.`}</p>
    <deckgo-highlight-code {...{
      "language": "js"
    }}>{`
          `}<code parentName="deckgo-highlight-code" {...{
        "slot": "code"
      }}>{`window.addEventListener('message', function (event) {
    //Here We have to check content of the message event  for safety purpose
    //event data contains message sent from page added in iframe
    if (event.data.hasOwnProperty("FrameHeight")) {
        //Set height of the Iframe
        $("#IframeId").css("height", event.data.FrameHeight);        
    }
});`}</code>{`
        `}</deckgo-highlight-code>
    <p>{`As it is dynamically generated should you wish to get rid of the internal overflow that might appear, set the style
to overflow-y:hidden; as well as scrolling="no". The scrolling attribute has since been depreciated, however, older
browsers still prefer it.`}</p>
    <h2 {...{
      "id": "page-transition-events",
      "style": {
        "position": "relative"
      }
    }}>{`Page Transition Events`}<a parentName="h2" {...{
        "href": "#page-transition-events",
        "aria-label": "page transition events permalink",
        "className": "anchor after"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a></h2>
    <p>{`Similar to the FrameHeight event, an event is also fired whenever a page transition within the iframe. You may use for any of the things, however, the most common is setting up an auto scroller to move your parent to the top of the page whenever the event fires. In this manner users can continue their application uninterrupted whilst filling in the form. Example:`}</p>
    <deckgo-highlight-code {...{
      "language": "js"
    }}>{`
          `}<code parentName="deckgo-highlight-code" {...{
        "slot": "code"
      }}>{` window.addEventListener('message', function (event) {
    //Here We have to check content of the message event  for safety purpose
    //event data contains message sent from page added in iframe
    if (event.data.hasOwnProperty("PageLoaded")) {
        window.scrollTo({ top: 0, behavior: 'smooth' });       
    }
});`}</code>{`
        `}</deckgo-highlight-code>
    <p>{`Please note that this code is an example that would vary depending upon the user on where it scrolls to and how it does so`}</p>
    <p><em parentName="p">{`DO NOT PERFORM BUSINESS LOGIC IN THE CLIENTS BROWSER`}</em>{`. Use the webhooks for mission critical processes.`}</p>
    <h2 {...{
      "id": "extracting-user-info",
      "style": {
        "position": "relative"
      }
    }}>{`Extracting User Info`}<a parentName="h2" {...{
        "href": "#extracting-user-info",
        "aria-label": "extracting user info permalink",
        "className": "anchor after"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a></h2>
    <p>{`Similar to the FrameHeight event, an event is also fired when data is captured from the user. For example, the users first name,
last name, email or phone number. This data can also be retrieved from the profile API. However, should you wish the information
as it is received (for example with live integration with your existing form) then this instantaneous feedback might be more
helpful. Example:`}</p>
    <deckgo-highlight-code {...{
      "language": "js"
    }}>{`
          `}<code parentName="deckgo-highlight-code" {...{
        "slot": "code"
      }}>{`window.addEventListener('message', function (event) {
    //Here We have to check content of the message event  for safety purpose
    //event data contains message sent from page added in iframe
    if (event.data.hasOwnProperty("ClientInfo")) {
     console.log(event.data);
    };
});`}</code>{`
        `}</deckgo-highlight-code>
    <p><em parentName="p">{`DO NOT PERFORM BUSINESS LOGIC IN THE CLIENTS BROWSER`}</em>{`. Use the webhooks for mission critical processes.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      